<template>
  <ion-page id="page-content">
    <ion-content :fullscreen="true">
      <topbar :bigtitle="true" :class="{ 'letter-ios-14': $store.state.deviceType == 1 }" title="Store Locator" :showBack="true"></topbar>
      <div class="store-locator margin-top-160">
        <div class="map-filter">
          <div class="mapTitle">
            <div class="item-table" @click="showAreaList()">
              <div class="tdcell" style="color: #a6a6a6">{{ titleText }}</div>
              <div class="tdcell category">
                <span class="icon icon-down" :class="{ opened: showSearch }"> <i class="icon-arrowRight"></i></span>
              </div>
            </div>
            <ul class="areaList" v-if="showSearch">
              <li :class="{ checkd: checkStore == 'All' }" @click="checkStoreEvent('All')">All</li>
              <li :class="{ checkd: checkStore == 'Nearby' }" @click="checkStoreEvent('Nearby')">Nearby</li>
              <li :class="{ checkd: checkStore == area.merchantAreaId }" @click="checkStoreEvent(area.areaInfo, area.merchantAreaId)" v-for="(area, index) in merchantArea" :key="index">
                {{ area.areaInfo }}
              </li>
            </ul>
          </div>
        </div>
        <GMapMap ref="mapRef" :center="center" :zoom="15" :style="mapStyle" :options="{
            zoomControl: false,
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
          }">
          <GMapMarker :position="store.center" :clickable="true" @click="openInfoWindow(store)" :icon="{
              url: pinIcon,
              scaledSize: { width: 52, height: 57 },
            }" v-for="(store, index) in markerList" :key="index">
            <GMapInfoWindow :opened="openedMarkerID == store.storeId" :options="{
                minWidth: 320,
              }">
              <div class="infoWindow">
                <i class="icon-close main-color" @click="openedMarkerID = ''"></i>
                <div class="main-color font-18 Bold store-title">
                  {{ store.name }}
                </div>
                <div class="item-table">
                  <div class="tdcell iconCell">
                    <i class="icon-location sub-color"></i>
                  </div>
                  <div class="tdcell labelCell Helveticaneue">
                    {{ store.address }}
                  </div>
                </div>
                <div class="item-table">
                  <div class="tdcell iconCell">
                    <i class="icon-tel sub-color"></i>
                  </div>
                  <div class="tdcell labelCell">
                    <a class="Medium-666" style="text-decoration: underline" :href="`tel:${store.phone}`">{{ store.phone }}</a>
                  </div>
                </div>
                <div class="item-table">
                  <div class="tdcell iconCell">
                    <i class="icon-clock sub-color"></i>
                  </div>
                  <div class="tdcell labelCell Helveticaneue" style="white-space: pre-wrap" v-html="store.businessInfo"></div>
                </div>
              </div>
            </GMapInfoWindow>
          </GMapMarker>
          <GMapMarker v-if="$store.state.lat" :position="center" :icon="{
              url: userIcon,
              scaledSize: { width: 51, height: 51 },
            }" />
        </GMapMap>
        <ion-modal :is-open="showStoreList" mode="ios" :breakpoints="[0.1, 0.5, 1]" :initialBreakpoint="0.5" :swipe-to-close="true" :handle="true" css-class="store-locator" @didDismiss="showStoreList = false">
          <ion-content>
            <div class="storeList">
              <div class="storeItem">
                <div v-for="(store, index) in markerList" :key="index" @click="selectEvent(store)" style="padding: 15px 0; border-bottom: 1px solid #c9cbcb" v-show="markerList.length > 0">
                  <p class="Bold font-18 main-color" style="padding-bottom: 10px">
                    {{ store.name }}
                  </p>
                  <div class="item-table">
                    <div class="tdcell iconCell">
                      <i class="icon-location sub-color"></i>
                    </div>
                    <div class="tdcell labelCell Helveticaneue">
                      {{ store.address }}
                    </div>
                  </div>
                  <div class="hr-line-dashed"></div>
                </div>
                <div v-show="markerList.length == 0" style="text-align: center; padding: 62px 0">No results</div>
              </div>
            </div>
          </ion-content>
        </ion-modal>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonModal } from "@ionic/vue";
import topbar from "@/components/topbar.vue";
import config from "../config";
export default {
  name: "storeLocator",
  mixins: [config.globalMixin],
  components: { IonContent, IonPage, topbar, IonModal },
  data() {
    return {
      storeList: [],
      markerList: [],
      merchantArea: [],
      apiKey: process.env.VUE_APP_GOOGLEMAPKEY,
      center: { lat: 22.322409, lng: 114.182154 },
      pinIcon: "",
      userIcon: "",
      mapStyle: "width:100vw;height:100vh",
      openedMarkerID: "",
      showSearch: false,
      checkStore: "",
      titleText: "All",
      showStoreList: false,
    };
  },
  methods: {
    init() {
      this.getStoreList();
      const height = window.innerHeight - 178 + "px";
      this.mapStyle = "width:100vw;height:" + height;
    },
    async getStoreList() {
      const objdata = this.getAuthObj();
      objdata.storeName = this.storeName;
      objdata.apiUrl = this.apiConfig.listStore;
      const data = await this.$store.dispatch("apiGetEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.storeList = data.data.result;
      this.storeList.forEach((store) => {
        store.phoneArray = store.phone.split("/");
        store.show = true;
        let have = false;
        for (let j = 0; j < this.merchantArea.length; j++) {
          if (store.merchantAreaId == this.merchantArea[j].merchantAreaId) {
            have = true;
          }
        }
        if (!have) {
          this.merchantArea.push({
            merchantAreaId: store.merchantAreaId,
            areaInfo: store.areaInfo,
          });
        }
        store.center = {
          lat: parseFloat(store.lat),
          lng: parseFloat(store.lng),
        };
      });
      this.markerList = this.storeList;
      this.fitBounds();
    },
    googleInit() {
      if (this.$store.state.lat) {
        this.center = {
          lat: parseFloat(this.$store.state.lat),
          lng: parseFloat(this.$store.state.lng),
        };
      }
    },
    openInfoWindow(store) {
      this.openedMarkerID = store.storeId;
    },
    showAreaList() {
      if (this.showSearch) {
        this.showSearch = false;
      } else {
        this.showSearch = true;
      }
    },
    sort_by_key(array, key) {
      return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    },
    checkStoreEvent(name, merchantAreaId) {
      this.showSearch = false;
      this.titleText = name;
      this.showStoreList = true;
      if (name == "All") {
        this.storeList.forEach((store) => {
          store.show = true;
        });
        this.markerList = this.storeList;
        this.fitBounds();
      } else if (name == "Nearby") {
        if (this.$store.state.lat) {
          const data = [];
          var data2 = [];
          for (let i = 0; i < this.storeList.length; i++) {
            const d = this.getDistance(
              { lat: this.$store.state.lat, lng: this.$store.state.lng },
              {
                lat: parseFloat(this.storeList[i].lat),
                lng: parseFloat(this.storeList[i].lng),
              }
            );
            this.storeList[i].d = d;
            data2.push(this.storeList[i]);
            // if (d < 1000) {
            //   data.push(this.storeList[i]);
            //   this.storeList[i].show = true;
            // } else {
            //   this.storeList[i].show = false;
            // }
          }
          this.sort_by_key(data2, "d");
          for (let i = 0; i < 5; i++) {
            if (data2[i]) {
              data.push(data2[i]);
              for (var k = 0; k < this.storeList.length; k++) {
                if (data2[i].storeId == this.storeList[k].storeId) {
                  this.storeList[k].show = true;
                }
              }
            }
          }
          this.markerList = data;
          if (data.length) {
            this.fitBounds(true);
          } else {
            this.center = {
              lat: parseFloat(this.$store.state.lat),
              lng: parseFloat(this.$store.state.lng),
            };
          }
        } else {
          this.getLocation();
          return;
        }
      } else {
        const data = [];
        for (let i = 0; i < this.storeList.length; i++) {
          if (this.storeList[i].merchantAreaId == merchantAreaId) {
            data.push(this.storeList[i]);
          }
        }
        this.markerList = data;
        this.fitBounds();
      }
    },
    fitBounds(bool) {
      this.$refs.mapRef.$mapPromise.then(() => {
        const bounds = new window.google.maps.LatLngBounds();
        for (let i = 0; i < this.markerList.length; i += 1) {
          const lati = parseFloat(this.markerList[i].lat);
          const long = parseFloat(this.markerList[i].lng);
          bounds.extend({
            lat: lati,
            lng: long,
          });
        }
        if (bool) {
          bounds.extend({
            lat: parseFloat(this.$store.state.lat),
            lng: parseFloat(this.$store.state.lng),
          });
        }
        this.$refs.mapRef.fitBounds(bounds);
      });
    },
    rad(x) {
      return (x * Math.PI) / 180;
    },
    getDistance(p1, p2) {
      const R = 6378137; // Earth’s mean radius in meter
      const dLat = this.rad(p2.lat - p1.lat);
      const dLong = this.rad(p2.lng - p1.lng);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.rad(p1.lat)) *
          Math.cos(this.rad(p2.lat)) *
          Math.sin(dLong / 2) *
          Math.sin(dLong / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = R * c;
      return d; // returns the distance in meter
    },
    selectEvent(store) {
      this.showStoreList = false;
      this.openedMarkerID = store.storeId;
      this.center = {
        lat: parseFloat(store.lat),
        lng: parseFloat(store.lng),
      };
    },
  },
  ionViewDidEnter() {
    this.init();
    this.$store.state.exitApp = false;
    this.pinIcon = require("@/assets/locator_pin.png");
    this.userIcon = require("@/assets/locator_yourlocation.png");
    this.googleInit();
    this.sendGAPageView("Store Locator");
  },
};
</script>
